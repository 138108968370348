import Vue from 'vue'
import Buefy from 'buefy'
import VueGtag from 'vue-gtag'
import VueEditableGrid from 'vue-editable-grid'
import LottieVuePlayer from '@lottiefiles/vue-lottie-player'
import clarity from './libs/clarity'
import 'vue-editable-grid/dist/VueEditableGrid.css'
import App from './App.vue'
// import './registerServiceWorker'
import router from './router'
import store from './store'

import './scss/application.scss'
import currencyFilter from './filters/currencyFilter'
import fileNameFilter from './filters/fileNameFilter'
import { formatDistanceStrict, dateTime, date } from './filters/datesFilters'
import Breadcrumb from '@/components/shared/Breadcrumb'
import TextInput from '@/components/shared/TextInput'
import SelectInput from '@/components/shared/SelectInput'
import SwitchInput from '@/components/shared/SwitchInput'
import ColorInput from '@/components/shared/ColorInput'
import Svg from '@/components/shared/Svg'
import NeedToSubscribeIcon from '@/components/shared/NeedToSubscribeIcon'
import ToPremiumButton from '@/components/shared/ToPremiumButton'
import PremiumCTA from './components/PremiumCTA'
import MainAlertHandler from '@/components/shared/MainAlertHandler'
import configuration from './configuration'

Vue.config.productionTip = false

const isApp = window.location.pathname.startsWith('/app')

clarity.init()

Vue.use(require('vue-script2'))
Vue.use(LottieVuePlayer)
Vue.use(VueGtag, {
  config: {
    id: isApp ? configuration.analyticsAppKey : configuration.analyticsMainKey,
    params: {
      send_page_view: false
    }
  },
  pageTrackerTemplate (to) {
    return {
      page_title: to.name,
      page_path: to.fullPath
    }
  }
}, router)

Vue.filter('currency', currencyFilter)
Vue.filter('formatDistanceStrict', formatDistanceStrict)
Vue.filter('dateTime', dateTime)
Vue.filter('date', date)
Vue.filter('fileName', fileNameFilter)

Vue.component('text-input', TextInput)
Vue.component('select-input', SelectInput)
Vue.component('switch-input', SwitchInput)
Vue.component('color-input', ColorInput)
Vue.component('premium-cta', PremiumCTA)
Vue.component('vue-editable-grid', VueEditableGrid)
Vue.component('app-icon', Svg)
Vue.component('need-to-subscribe-icon', NeedToSubscribeIcon)
Vue.component('to-premium-button', ToPremiumButton)
Vue.component('main-alert-handler', MainAlertHandler)
Vue.component('breadcrumb', Breadcrumb)

Vue.use(Buefy)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
