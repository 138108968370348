import businessClientsService from '@/services/business-clients.service'
import businessService from '@/services/business.service'

const state = () => ({
  clients: [],
  error: false,
  loading: false
})

const mutations = {
  setError: (state, error) => {
    state.error = error
  },
  setLoading: (state, loading) => {
    state.loading = loading
  },
  setClients: (state, clients) => {
    state.clients = clients
  }
}

const actions = {
  getClients: async ({ rootState, commit } = {}) => {
    const currentBusiness = rootState.appBusiness.business
    commit('setLoading', true)
    commit('setError', false)
    const result = await businessClientsService.getByBusinessId(currentBusiness.id)
    if (result) {
      commit('setClients', result)
    } else {
      commit('setError', true)
    }
    commit('setLoading', false)
  },
  getStaticBusinessById: async ({ state }, businessId) => {
    const associated = state.clients.find(client => client.businessAssociatedId === businessId)
    if (associated) {
      return associated.businessAssociated
    }
    const result = await businessService.getStaticById(businessId)
    return result
  }
}

const getters = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
