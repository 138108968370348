import Clarity from '@microsoft/clarity'

export default {
  init: () => {
    Clarity.init('p8xist62th')
  },
  setIdentify: (userId, pageName, planCode) => {
    Clarity.identify(userId, undefined, pageName)
    Clarity.setTag('plan-code', planCode)
  }
}
