import { api } from './api'
import { onBusinessSaved } from './global-events.service'

const patchBusiness = async (business) => {
  const result = await api.patch('/business', business)
  if (result) {
    onBusinessSaved.invoke(business)
  }
  return result
}

export default {
  getAllMyBusiness: () => api.get('/business'),
  postMyBusiness: business => api.post('/business/my-business', business),
  patchBusiness,
  getById: (id, params) => api.get(`/business/${id}`, { params }),
  getStaticById: (id, params) => api.get(`/business/static/${id}`, { params }),
  updateOrders: (businessId, categories, newItemId) => {
    const categoriesOrder = []
    const itemsOrder = []
    categories.forEach((category) => {
      categoriesOrder.push(category.id)
      category.items.forEach((item) => {
        itemsOrder.push(item.id)
      })
    })
    if (newItemId) {
      itemsOrder.push(newItemId)
    }
    return patchBusiness({ id: businessId, categoriesOrder: JSON.stringify(categoriesOrder), itemsOrder: JSON.stringify(itemsOrder) })
  }
}
