import businessService from '@/services/business.service'
import businessLocationsService from '@/services/business-locations.service'
import businessSessionsService from '@/services/business-sessions.service'
import { decode } from '@/helpers/base64.helper'

const postSession = (businessLocationId, currentSessionCode) => businessSessionsService.post({
  businessLocationId,
  currentSessionCode
})

const state = () => ({
  business: {},
  parentBusiness: null,
  businessOrderDetails: [],
  location: null,
  sessionCode: localStorage.codeSession,
  sessionClosed: false,
  locationIsBusy: false,
  error: false,
  loading: false
})

const mutations = {
  setError: (state, error) => {
    state.error = error
  },
  setLoading: (state, loading) => {
    state.loading = loading
  },
  setBusiness: (state, business) => {
    state.business = business
  },
  setParentBusiness: (state, business) => {
    state.parentBusiness = business
  },
  setLocation: (state, location) => {
    state.location = location
  },
  setSession: (state, session) => {
    if (session.sessionCode) {
      state.sessionCode = session.sessionCode
      businessSessionsService.setLocalCode(session.sessionCode)
    }
    state.sessionClosed = session.closed
    state.locationIsBusy = !session.closed && !session.sessionCode
  },
  setEmptySession: (state) => {
    state.sessionCode = null
    businessSessionsService.setLocalCode(null)
    state.sessionClosed = false
    state.locationIsBusy = false
    localStorage.removeItem('tfAppQuery')
  },
  addOrderItem: (state, orderDetail) => {
    let index = state.businessOrderDetails.length
    if (orderDetail._tmpId) {
      index = state.businessOrderDetails.findIndex(detail => detail._tmpId === orderDetail._tmpId)
    }
    orderDetail._tmpId = new Date().getTime()
    state.businessOrderDetails.splice(index, 0, orderDetail)
  },
  removeOrderDetail: (state, orderDetail) => {
    const index = state.businessOrderDetails.findIndex(detail => detail._tmpId === orderDetail._tmpId)
    if (index >= 0) {
      state.businessOrderDetails.splice(index, 1)
    }
  },
  clearOrder: (state) => {
    state.businessOrderDetails = []
  }
}

const actions = {
  initializeBusiness: async ({ commit }, { query, associatedId }) => {
    if (!query) {
      commit('setError', true)
      return
    }
    const base64Result = decode(query)
    if (!base64Result) {
      commit('setError', true)
      return
    }
    const [businessId, locationCode] = base64Result.split('-')
    if (!businessId) {
      commit('setError', true)
      return
    }

    commit('setLoading', true)
    const [business, location, parentBusiness] = await Promise.all([
      businessService.getById(associatedId || businessId, { includeProperties: true }),
      locationCode ? businessLocationsService.getByCode(locationCode) : Promise.resolve(null),
      associatedId ? businessService.getById(businessId) : Promise.resolve(null)
    ])
    if (business) {
      commit('setBusiness', business)
      commit('setLocation', location)
    } else {
      commit('setBusiness', {})
      commit('setLocation', {})
      commit('setError', true)
    }
    commit('setParentBusiness', parentBusiness)
    commit('setLoading', false)
  },
  initializeSession: async ({ commit, state }, { sessionCode, isNewRead }) => {
    if (!state.location || !state.location.id) return
    commit('setLoading', true)
    let result = await postSession(state.location.id, sessionCode || state.sessionCode)
    if (isNewRead && result && result.closed) {
      // Table was closed, we need to create new session and deprecate the current
      result = await postSession(state.location.id, null)
    }

    if (result) {
      if (!result.sessionCode && sessionCode) {
        // si de back no llegó código, y para la inicializacion se ingresó el código manualmente
        // se registra dicho código en localstorage y en el estado, aun si la mesa está cerrada.
        result.sessionCode = sessionCode
      }
      commit('setSession', result)
    } else {
      commit('setSession', {})
      commit('setError', true)
    }
    commit('setLoading', false)
  },
  clearSession: async ({ commit }) => {
    commit('setEmptySession')
  },
  addOrderItem: async ({ commit }, orderDetail) => {
    commit('addOrderItem', orderDetail)
  },
  removeOrderDetail: async ({ commit }, orderDetail) => {
    commit('removeOrderDetail', orderDetail)
  },
  clearOrder: async ({ commit }) => {
    commit('clearOrder')
  }
}

const getters = {
  currentOrderQuantity: state => {
    return !!state.businessOrderDetails &&
      state.businessOrderDetails.reduce((sum, item) => sum + item.requestQuantity, 0)
  },
  sessionValid: state => !state.location || (state.sessionCode && !state.sessionClosed)
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
